import { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import Layout from 'components/layout/layout';

import siteConfig from 'hooks/site-config';
import 'i18n/i18n';
import ParkingTerminalPage from 'pages/parking-terminal';

const App: FC = () => {
  document.title = siteConfig.pageTitle;

  return (
    <Layout>
      <Routes>
        <Route path={'*'} element={<ParkingTerminalPage />} />
      </Routes>
    </Layout>
  );
};

export default App;
