import {
  ChangeEvent,
  FC,
  FormEvent,
  useEffect,
  useMemo,
  useState,
} from 'react';

import axios from 'axios';

import { Button, Form, FormGroup, Loader } from 'components';

import { Alert } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { useTranslation } from 'react-i18next';
import InputText from 'components/inputs/input-text';
import { TranslatedString } from 'i18n/translations/no';

import './ParkingTerminalForm.scss';
import { parseTemplate } from 'url-template';

type Props = {
  submitLink: string;
};
type LinkShape = {
  Rel: string;
  Href: string;
};
type ParkingSessionApiResponse = {
  Items: [];
  Links: LinkShape[];
};
/**
 * Ensures correct format by removing all unallowed characters
 * when the user tries to add an un-allowed character
 */
export const ensureLicenseNumberFormat = (value: string): string =>
  value
    /**
     * Removes all empty spaces from value
     */
    .replace(' ', '')
    /**
     * Converts text to upper case
     */
    .toLocaleUpperCase()
    /**
     * Removes everything that is not:
     * - a letter
     * - a norwegian letter
     * - a number
     */
    .replaceAll(/[^A-Z0-9\WÆÅØ]/gi, '');

const ParkingTerminalForm: FC<Props> = ({ submitLink }) => {
  const { t } = useTranslation();
  const [submitError, setSubmitError] = useState<{
    code?: string;
    message: TranslatedString;
  }>();
  const [inputError, setInputError] = useState('');
  const [hasOpenedGate, setHasOpenedGate] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [message, setMessage] = useState<{ text: string; type: 'success' | 'error' } | null>(null);

  const [regNo, setRegNo] = useState('');

  useEffect(() => {
    if (hasOpenedGate) {
      setTimeout(() => {
        setHasOpenedGate(false);
        setRegNo('');
      }, 10000);
    }
  }, [hasOpenedGate]);

  useEffect(() => {
    if (inputError) {
      setTimeout(() => {
        setInputError('');
        setRegNo('');
      }, 10000);
    }
  }, [inputError]);
  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    setMessage(null);

    if (!submitLink) {
      setIsSubmitting(false);
      setMessage({ text: 'Gate cannot be opened', type: 'error' });
      return;
    }

    try {
      const urlTemplate = parseTemplate(decodeURI(submitLink));
      const parkingSessionLink = urlTemplate.expand({ regno: regNo });
      
      const response = await axios.get<ParkingSessionApiResponse>(parkingSessionLink);
      const data = response.data;

      if (data.Items.length === 0) {
        setMessage({ text: "We did not find anything on this license plate number. Double-check", type: 'error' });
      } else if (data.Items.length === 1) {
        const validateLink = data.Links.find(link => link.Rel === 'validate')?.Href;
        if (validateLink) {
          const validateResponse = await axios.post(validateLink);
          if (validateResponse.status === 200) {
            setMessage({ text: "Your parking has been validated. You can now exit the parking.", type: 'success' });
          } else {
            throw new Error('Validation failed');
          }
        } else {
          throw new Error('Validate link not found');
        }
      } else {
        setMessage({ text: "We found more than one match for this license plate. Write complete license plate number or check with reception", type: 'error' });
      }
    } catch (error) {
      console.error(error);
      setMessage({ text: 'An error occurred while processing your request', type: 'error' });
    } finally {
      setIsSubmitting(false);
    }
  };
   
  const updateValue = (value: string) => {
    console.log('update value called, with value', value);
    setRegNo(value);
    setSubmitError(undefined);

    if (inputError) {
      setInputError('');
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    updateValue(ensureLicenseNumberFormat(value));
  };

  const errorMessage = useMemo(() => {
    if (!submitError) {
      return '';
    }

    let msg = t(submitError.message);
    if (submitError.code) {
      msg += ' [' + submitError.code + ']';
    }
    return msg;
  }, [submitError, t]);

  return (
    <Form onSubmit={handleSubmit}>
      <FormGroup formatClassName="dropdown-combo">
        <InputText
          id="registration-number-input"
          label={t('Registration number')}
          value={regNo}
          error={errorMessage ? errorMessage : inputError}
          onChange={handleChange}
          maxLength={8}
          required
        />
        {!!regNo && (
          <div
            className="clear-icon"
            onClick={() => {
              updateValue('');
              setMessage(null)
            }}
          >
            <ClearIcon style={{ fontSize: '40px', cursor:'pointer' }} />
          </div>
        )}
      </FormGroup>
      {message && (
        <Alert variant="filled" severity={message.type}>
          {message.text}
        </Alert>
      )}
    {!message &&  <Button type="submit" disabled={isSubmitting}>
        <Loader loading={isSubmitting}>{t('Submit')}</Loader>
      </Button>}
    </Form>
  );
};

export default ParkingTerminalForm;
